import { Controller } from "stimulus"
import { useDispatch } from "stimulus-use"
import camelCase from "../utils/camel_case"

export default class extends Controller {
  static targets = ["video"]
  static values = { backgroundImageUrl: String }

  connect() {
    useDispatch(this)
    this.element[camelCase(this.identifier)] = this
  }

  pause() {
    if (!this.video) return
    if (!this.isCurrent) return
    this.video.pause()
  }

  unpause() {
    if (!this.video) return
    if (!this.isCurrent) return
    this.video.play()
  }

  loaded() {
    this.dispatch("loaded")
  }

  loading() {
    this.dispatch("loading")
  }

  get blurhash() {
    return this.element.dataset.blurhash
  }

  get backgroundImage() {
    return this.backgroundImageUrlValue
  }

  get isCurrent() {
    return this.element.parentElement.classList.contains(
      "Carousel-Slide--current"
    )
  }

  get video() {
    if (!this.hasVideoTarget) return
    return this.videoTarget.videoComponent
  }
}
