import { Controller } from "stimulus"
import { useDispatch } from "stimulus-use"
import camelCase from "./utils/camel_case"

export default class extends Controller {
  static classes = ["currentDot", "currentSlide"]
  static targets = ["slide", "dot"]
  static values = { index: Number }

  initialize() {
    useDispatch(this)
  }

  connect() {
    this.element[camelCase(this.identifier)] = this
  }

  next() {
    this.indexValue++
  }

  previous() {
    this.indexValue--
  }

  select(event) {
    this.indexValue = this.dotTargets.indexOf(event.target)
  }

  indexValueChanged() {
    this._toggleDots()
    this._toggleSlides()
    this.dispatch("selected", { slide: this.currentSlide })
  }

  get atBeginning() {
    return this.indexValue === 0
  }

  get atEnd() {
    return this.indexValue === this.slideTargets.length - 1
  }

  get currentSlide() {
    return this.slideTargets[this.indexValue]
  }

  _toggleDots() {
    this.dotTargets.forEach((dot, index) => {
      dot.classList.toggle(this.currentDotClass, index === this.indexValue)
    })
  }

  _toggleSlides() {
    this.slideTargets.forEach((slide, index) => {
      slide.classList.toggle(this.currentSlideClass, index === this.indexValue)
    })
  }
}
