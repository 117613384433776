import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["toggles"]

  toggle() {
    this.hidden = !this.hidden
  }

  set hidden(value) {
    this.togglesTarget.hidden = value
  }

  get hidden() {
    return this.togglesTarget.hidden
  }
}
