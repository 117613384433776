import { Controller } from "stimulus"
import camelCase from "./utils/camel_case"

export default class extends Controller {
  static targets = ["vision", "viewport", "template", "backgroundImage"]
  static values = { index: Number }
  static classes = [
    "atBeginning",
    "atEnd",
    "currentVision",
    "nextVision",
    "paused",
    "previousVision"
  ]

  connect() {
    this.element[camelCase(this.identifier)] = this
    this.indexValue = 0
    this.loadNext()
    this.toggleVisionClasses()
    this.element.classList.add(this.atBeginningClass)
  }

  next() {
    if (!this.currentVision) return
    if (this.atEnd) {
      if (this.autoplayer) this.autoplayer.stop()
      return
    } else if (this.currentVision.atEnd) {
      if (this.autoplayer) this.autoplayer.pause()
      this.nextVision()
    } else {
      this.currentVision.next()
      this.updateBackground()
    }
    this.toggleEdgeClasses()
  }

  previous() {
    if (!this.currentVision) return
    if (this.atBeginning) {
      if (this.autoplayer) this.autoplayer.stop()
      return
    } else if (this.currentVision.atBeginning) {
      if (this.autoplayer) this.autoplayer.pause()
      this.previousVision()
    } else {
      this.currentVision.previous()
      this.updateBackground()
    }
    this.toggleEdgeClasses()
  }

  nextVision() {
    if (this.autoplayer) this.autoplayer.stop()
    this.viewport.rotate("forward")
    this.indexValue++
    this.loadNext()
  }

  previousVision() {
    if (this.autoplayer) this.autoplayer.stop()
    this.viewport.rotate("backward")
    this.indexValue--
  }

  rotated() {
    this.toggleVisionClasses()
  }

  indexValueChanged() {
    this.element.classList.remove(this.pausedClass)
    this.toggleEdgeClasses()
  }

  updateBackground() {
    if (!this.currentVision) return
    this.backgroundImageTarget.setAttribute("hash", this.currentVision.blurhash)
  }

  toggleEdgeClasses() {
    if (!this.currentVision) return
    this.element.classList.toggle(this.atBeginningClass, this.atBeginning)
    this.element.classList.toggle(this.atEndClass, this.atEnd)
  }

  toggleVisionClasses() {
    this.visionTargets.forEach((vision, index) => {
      vision.classList.toggle(
        this.currentVisionClass,
        index === this.indexValue
      )
      vision.classList.toggle(
        this.nextVisionClass,
        index === this.indexValue + 1
      )
      vision.classList.toggle(
        this.previousVisionClass,
        index === this.indexValue - 1
      )
    })
  }

  loadNext() {
    if (!this.hasTemplateTarget) return
    const template = this.templateTarget
    const clone = template.content.cloneNode(true)
    this.viewportTarget.insertBefore(clone, template)
    template.remove()
  }

  handleVideoToggle() {
    this.element.classList.add(this.pausedClass)
  }

  handleVideoPlay() {
    this.element.classList.remove(this.pausedClass)
  }

  get atBeginning() {
    return this.indexValue === 0 && this.currentVision.atBeginning
  }

  get atEnd() {
    return (
      this.indexValue === this.visionTargets.length - 1 &&
      this.currentVision.atEnd
    )
  }

  get currentVision() {
    if (!this.currentVisionTarget) return
    return this.currentVisionTarget.visionComponent
  }

  get currentVisionTarget() {
    return this.visionTargets[this.indexValue]
  }

  get autoplayer() {
    return this.element.autoplayer
  }

  get viewport() {
    if (!this.hasViewportTarget) return
    return this.viewportTarget.viewport
  }
}
