import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["toggle", "body"]
  static values = {
    open: Boolean,
    animateHeight: Boolean,
    collapseSiblings: Boolean
  }

  openValueChanged() {
    this.toggleBody()
    this.toggleTarget.setAttribute("aria-expanded", this.openValue)
    this.collapseSiblings()
  }

  toggle() {
    this.openValue = !this.openValue
  }

  open() {
    this.openValue = true
  }

  close() {
    this.openValue = false
  }

  collapseSiblings() {
    if (!this.collapseSiblingsValue) return
    if (!this.openValue) return
    this.siblingControllers.forEach(siblingController => {
      if (siblingController && siblingController.expanded) {
        siblingController.toggle()
      }
    })
  }

  toggleBody() {
    if (this.animateHeightValue) {
      this.sectionHeight = this.getBodyHeight()
      this.bodyTarget.setAttribute("aria-hidden", !this.openValue)
    } else {
      this.bodyTarget.hidden = !this.openValue
    }
  }

  getBodyHeight() {
    if (this.openValue) {
      return this.bodyTarget.scrollHeight
    } else {
      return 0
    }
  }

  set sectionHeight(height) {
    if (!this.hasBodyTarget) return
    this.bodyTarget.style.height = `${height}px`
  }

  get expanded() {
    if (!this.toggleTarget) return
    return this.toggleTarget.getAttribute("aria-expanded") === "true" || false
  }

  get siblingControllers() {
    return [...this.siblings].map(sibling => {
      return this.application.getControllerForElementAndIdentifier(
        sibling,
        this.identifier
      )
    })
  }

  get siblings() {
    let siblings = []
    let sib = this.element.parentNode.firstChild
    while (sib) {
      if (sib.nodeType === 1 && sib !== this.element) {
        siblings.push(sib)
      }
      sib = sib.nextSibling
    }
    return siblings
  }
}
