import { Controller } from "stimulus"
import { useResize } from "stimulus-use"

export default class extends Controller {
  static targets = ["progress", "dot", "range", "audio"]
  static values = { progress: Number, paused: Boolean }
  static classes = ["head", "playing", "paused"]

  connect() {
    useResize(this)
    this.progressValue = 0
    this.pausedValue = this.audioTarget.paused
  }

  togglePlay() {
    this.audioTarget.paused ? this.audioTarget.play() : this.audioTarget.pause()
  }

  pausedValueChanged() {
    this.element.classList.toggle(this.playingClass, !this.pausedValue)
    this.element.classList.toggle(this.pausedClass, this.pausedValue)
  }

  togglePausedValue() {
    this.pausedValue = this.audioTarget.paused
  }

  scrub(event) {
    this.currentTime = event.target.value
  }

  timeupdate(event) {
    this.rangeTarget.value = parseInt(event.target.currentTime)
    this.progressValue = event.target.currentTime
  }

  resize() {
    if (this.editMode) return
    this.setHeadPosition()
  }

  progressValueChanged() {
    this.setHeadPosition()
  }

  setHeadPosition() {
    const headIndex = this.headIndex
    this.dotTargets.forEach((dot, index) => {
      dot.classList.toggle(this.headClass, index === headIndex)
    })
  }

  set currentTime(value) {
    this.audioTarget.currentTime = value
  }

  get editMode() {
    return this.element.closest(".Apparatus-Blocks-Editor")
  }

  get headIndex() {
    return Math.min(
      Math.floor(this.dotTargets.length * this.percentageProgress),
      this.dotTargets.length - 1
    )
  }

  get percentageProgress() {
    return this.progressValue / this.rangeTarget.max
  }
}
