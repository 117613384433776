import { Controller } from "stimulus"
import { useDispatch } from "stimulus-use"

import camelCase from "./utils/camel_case"

export default class extends Controller {
  static targets = ["carousel", "glimpse"]

  connect() {
    useDispatch(this)
    this.element[camelCase(this.identifier)] = this
  }

  next() {
    this.carouselTarget.carouselComponent.next()
  }

  previous() {
    this.carouselTarget.carouselComponent.previous()
  }

  toggleVideos(event) {
    if (!this.isCurrent) return
    const videos = this.carouselTarget.querySelectorAll("video")
    videos.forEach(video => video.pause())
    const video = event.detail.slide.querySelector("video")
    if (video) {
      const duration = Math.floor(
        parseFloat(video.dataset["attachments-VideoComponentDurationValue"])
      )
      this.dotAnimationDuration = duration
      this.dispatch("video-toggle")
      video.play()
    } else {
      this.style = null
    }
  }

  handleVideoPlay() {
    if (!this.isCurrent) return
    this.dispatch("video-play")
  }

  handleVideoEnded() {
    if (!this.isCurrent) return
    this.dispatch("video-ended")
  }

  pauseVideos() {
    this.glimpseTargets.forEach(glimpse => glimpse.glimpse.pause())
  }

  unpauseVideos() {
    this.glimpseTargets.forEach(glimpse => glimpse.glimpse.unpause())
  }

  get blurhash() {
    if (!this.currentGlimpse) return
    return this.currentGlimpse.blurhash
  }

  get backgroundImage() {
    if (!this.currentGlimpse) return
    return this.currentGlimpse.backgroundImage
  }

  get currentGlimpse() {
    if (!this.currentSlide) return
    const glimpse = this.glimpseTargets.find(
      glimpse => glimpse.parentNode == this.currentSlide
    )
    if (glimpse) return glimpse.glimpse
  }

  get currentSlide() {
    if (!this.carousel) return
    return this.carousel.currentSlide
  }

  set dotAnimationDuration(value) {
    this.style = `--dot-animation-duration: ${value}s;`
  }

  set style(value) {
    this.element.style = value
  }

  get atBeginning() {
    return this.carouselTarget.carouselComponent.atBeginning
  }

  get atEnd() {
    return this.carouselTarget.carouselComponent.atEnd
  }

  get carousel() {
    if (!this.carouselTarget) return
    return this.carouselTarget.carouselComponent
  }

  get isCurrent() {
    return this.element.classList.contains("Vision--current")
  }
}
