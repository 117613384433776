import { Controller } from "stimulus"
import { useDispatch } from "stimulus-use"
import camelCase from "../utils/camel_case"

export default class extends Controller {
  static classes = ["animating", "animated", "forward", "backward"]

  connect() {
    useDispatch(this)
    this.element[camelCase(this.identifier)] = this
  }

  rotate(direction) {
    this.element.classList.remove(this.animatedClass)
    this.element.classList.add(this.animatingClass)
    this.element.classList.toggle(this.forwardClass, direction === "forward")
    this.element.classList.toggle(this.backwardClass, direction === "backward")
  }

  rotated(event) {
    if (event.propertyName != "transform") return
    this.element.classList.remove(this.animatingClass)
    this.element.classList.add(this.animatedClass)
    this.element.classList.remove(this.forwardClass)
    this.element.classList.remove(this.backwardClass)
    this.dispatch("rotated")
  }
}
