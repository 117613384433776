import { Controller } from "stimulus"
import { useDispatch } from "stimulus-use"
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock"

export default class extends Controller {
  static targets = ["menu"]
  static classes = ["open"]

  connect() {
    useDispatch(this)
  }

  disconnect() {
    enableBodyScroll(this.menuTarget)
  }

  toggle() {
    this.open = !this.open
    this.element.classList.toggle(this.openClass, this.open)
    if (this.open) {
      this.dispatch("open")
    } else {
      this.dispatch("close")
    }
  }

  get open() {
    return !this.menuTarget.hidden
  }

  set open(open) {
    this.menuTarget.hidden = !open
    if (open) {
      disableBodyScroll(this.menuTarget)
    } else {
      enableBodyScroll(this.menuTarget)
    }
  }
}
