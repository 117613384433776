// import Timer from "./timer"
import pauseMe from "pause-me"

class Ticker {
  constructor(time = 3000, tickable) {
    this.time = time
    this.state = "stopped"
    this.tickable = tickable
  }

  start() {
    if (this.state === "running") return
    this.state = "running"
    this.timer = pauseMe(() => this.tickable.tick(), this.time, true)
  }

  stop() {
    this.state = "stopped"
    this.timer.stop()
  }

  restart() {
    this.timer.stop()
    this.timer.start()
  }

  pause() {
    this.timer.pause()
    this.state = "paused"
  }

  unpause() {
    this.timer.resume()
    this.state = "running"
  }
}

export default Ticker
