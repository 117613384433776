function camelCase(str) {
  return str
    .split("--")
    .slice(-1)[0]
    .split(/[-_]/)
    .map(w => w.replace(/./, m => m.toUpperCase()))
    .join("")
    .replace(/^\w/, c => c.toLowerCase())
}

export default camelCase
