import Flatpickr from "stimulus-flatpickr"

export default class extends Flatpickr {
  connect() {
    this.config = {
      enableTime: true,
      noCalendar: true,
      dateFormat: "H:i"
    }

    super.connect()
  }
}
