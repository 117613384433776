import { Controller } from "stimulus"
import { useIntersection, useDispatch } from "stimulus-use"
import camelCase from "../utils/camel_case"

export default class extends Controller {
  static values = {
    duration: Number
  }

  interSectionOptions = {
    root: this.element.parentElement
  }

  connect() {
    useIntersection(this)
    useDispatch(this)
    this.element[camelCase(this.identifier)] = this
    this._bindEvents()
  }

  disconnect() {
    this._unbindEvents()
  }

  onPlay() {
    this.dispatch("play")
  }

  onEnded() {
    this.dispatch("ended")
  }

  pause() {
    this.element.pause()
  }

  play() {
    this.element.play()
  }

  appear(_) {
    this.load()
  }

  load() {
    this.element.preload = "auto"
  }

  _bindEvents() {
    this.element.addEventListener("play", this.onPlay.bind(this))
    this.element.addEventListener("ended", this.onEnded.bind(this))
  }

  _unbindEvents() {
    this.element.removeEventListener("play", this.onPlay.bind(this))
    this.element.removeEventListener("ended", this.onEnded.bind(this))
  }
}
