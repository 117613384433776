import { Controller } from "stimulus"
import { useDispatch } from "stimulus-use"
import Ticker from "./utils/ticker"
import camelCase from "./utils/camel_case"

export default class extends Controller {
  static classes = ["autoPlaying", "playing"]
  static values = {
    autoPlaying: Boolean,
    state: String
  }
  static targets = ["media"]

  connect() {
    this.element[camelCase(this.identifier)] = this
    useDispatch(this)
    this.start()
  }

  start() {
    if (this.playing) return
    this.autoPlayingValue = true
    this.ticker = new Ticker(7000, this)
    this.ticker.start()
    this.stateValue = "playing"
  }

  stop() {
    if (!this.playing) return
    if (!this.ticker) return
    this.ticker.stop()
    this.stateValue = "stopped"
    this.autoPlayingValue = false
  }

  tick() {
    this.element.visionsComponent.next()
  }

  pause() {
    if (!this.playing) return
    if (!this.ticker) return
    this.ticker.pause()
    this.stateValue = "paused"
    this.dispatch("pause")
  }

  unpause() {
    if (this.playing) return
    if (!this.ticker) return
    this.ticker.unpause()
    this.stateValue = "playing"
    this.dispatch("unpause")
  }

  restart() {
    this.ticker.restart()
    this.stateValue = "playing"
  }

  hold() {
    this.held = true
    this.pause()
  }

  release() {
    if (!this.held) return
    this.unpause()
    this.held = false
  }

  stateValueChanged() {
    this.element.classList.toggle(this.playingClass, this.playing)
  }

  autoPlayingValueChanged() {
    this.element.classList.toggle(this.autoPlayingClass, this.autoPlayingValue)
  }

  handleVisibilityChange() {
    this.idle = document.hidden
    if (this.idle) {
      this.pause()
    } else {
      this.unpause()
    }
  }

  handleVideoPlay() {
    this.ticker.pause()
  }

  handleVideoEnded() {
    this.tick()
    this.ticker.restart()
  }

  startWithTime(time) {
    this.ticker.stop()
    this.ticker.time = time
    this.ticker.start()
    this.stateValue = "playing"
  }

  get paused() {
    return !this.playing
  }

  get playing() {
    return this.stateValue === "playing"
  }

  get visions() {
    return this.element.visionsComponent
  }
}
